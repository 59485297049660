import globalNews from '../../../images/new/logos/global.svg';
import huffPost from '../../../images/new/logos/huffington-post.svg';
import blogTo from '../../../images/new/logos/blog-to.svg';
import ctv from '../../../images/new/logos/ctv.svg';
import breakfastTelevision from '../../../images/new/logos/breakfast-television.svg';
import rogers from '../../../images/new/logos/rogers.svg';
import todaysParent from '../../../images/new/logos/today-s-parent.svg';

export const press = [
  {
    id: 0,
    source: globalNews,
    name: 'Global News',
  },
  {
    id: 1,
    source: huffPost,
    name: 'Huffington Post',
  },
  {
    id: 2,
    source: blogTo,
    name: 'blogTO',
  },
  {
    id: 3,
    source: ctv,
    name: 'CTV Morning Live',
  },
  {
    id: 4,
    source: breakfastTelevision,
    name: 'Breakfast Television',
  },
  {
    id: 5,
    source: rogers,
    name: 'Rogers',
  },
  {
    id: 6,
    source: todaysParent,
    name: "Today's Parent",
  },
];
